<template>
  <div class="article">
    <div class="left">
      <h3 class="title">{{ article.title }}</h3>
      <div class="content" v-html="article.content"></div>
    </div>
    <div class="right">
      <el-form label-position="right" label-width="80px" :model="article">
        <el-form-item label="文章标题">
          <el-input v-model="article.title" placeholder="请输入文章标题"></el-input>
        </el-form-item>
        <el-form-item label="封面图片">
          <div class="logo" @click="uploadImage">
            <img v-if="article.logo" :src="article.logo">
            <span class="add el-icon-plus"></span>
          </div>
        </el-form-item>
        <el-form-item label="正文内容">
          <vue-html5-editor 
            :auto-height="false"
            :content="article.content" 
            :height="400" 
            @change="showEditorContent">
          </vue-html5-editor>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveContent('保存')">保存</el-button>
          <el-button @click="saveContent('保存并发布')" type="primary">保存并发布</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import UploadImage from '@/websites/cms/js/dialog'
import VueHtml5Editor from 'vue-html5-editor'
Vue.use(VueHtml5Editor, {
  name: 'vue-html5-editor',
  showModuleName: false,
  language: "zh-cn",
   visibleModules: [
    "text",
    "color",
    "font",
    "align",
    "list",
    "link",
    "unlink",
    "tabulation",
    "image",
    "hr",
    "eraser",
  ],
})
export default {
  data(){
    return {
      article: {
        title: "",
        logo: "",
        content: ""
      }
    }
  },
  mounted(){
    // 获取文章内容
    if(this.$route.query.id){
      this.getContent(this.$route.query.id);
    }
  },
  methods: {
    // 处理vue-html5-editor的内容并展示
    showEditorContent(data){
      this.article.content = data;
    },

    // 查询文章详情
    async getContent(id){
      let res = await this.$ZGManager.getArticleContent(id);
      if(res.status == 200 && res.data){
        this.article.title = res.data.title;
        this.article.logo = res.data.logo;
        let content = JSON.parse(res.data.configs).content;
        this.article.content = content.replace(/data-src/g, 'style="max-width: 100%!important;" src');
      }
    },

    // 保存文章
    async saveContent(type){
      if(!this.article.title || !this.article.logo || !this.article.content){
        return this.$message.error("检查文章标题，封面或内容是否完整");
      }
      let params = {
        title: this.article.title,
        logo: this.article.logo,
        configs: {
          content: this.article.content
        }
      }
      if(this.$route.query.id) params.id = this.$route.query.id
      if(type == "保存并发布") params.status = 2; 
      let res = await this.$ZGManager.createArticle(params);
      if(res.status == 200 && res.data){
        this.$message.success("保存成功");
        // let timer = setTimeout(() => {
        //   window.close();
        // }, 1000);
      }
    },

    // 上传/替换封面图
    uploadImage(){
      UploadImage.show({
        type:"crooperImage",
        cropWidth: 250,
        cropHeight: 200,
        fixedArray: ['5', '4'],
        isfixed: true,
        isfixedBox:true,
        selected: (item) => {
          console.log(item);
          this.article.logo = item.url;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.left{
  width: calc(375px - 0.6rem);
  height: calc(100% - 0.6rem);
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  position: relative;
  padding: 0.3rem;
  .title{
    font-size: 20px;
    font-weight: bold;
    color: #111;
  }
  .content{
    margin-top: 0.3rem;
  }
}
.right{
  width: calc(100% - 405px);
  height: calc(100% - 20px);
  float: right;
  background-color: #fff;
  padding: 10px;
  margin-left: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  .logo{
    width: 200px;
    height: 160px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
    .add{
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: 150px;
      text-align: center;
      font-size: 20px;
      color: #bbbbbb;
    }
    img{
      width: 100%;
      height: 100%;
      &:hover{

      }
    }
  }
}
</style>